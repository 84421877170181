import React from "react";
import { graphql } from "gatsby";
// Components
import { Container, Heading, Box as Section, Text } from "@chakra-ui/react";
import { SEO } from "../components/SEO";
import { PostList } from "../components/PostList";
import { TagList } from "../components/TagList";

const PostsPage = ({ data }) => {
  const { edges: posts } = data.allMdx;

  console.log(posts);
  return (
    <>
      <SEO title="Blog" />
      <Section mt="50px" backgroundColor="brand.lightShades">
        <Container maxWidth="container.md" p={6}>
          <Heading color="brand.main" textTransform="uppercase" fontSize={["xl", "2xl"]}>
            Tips, tricks and thoughts
          </Heading>
          <Text mt={2} fontSize={["sm"]}>
            I blog about things I've recently learned or just thought about &mdash; primarily so that I don't forget about it &mdash; and by
            chance, it may just also be of interest to <i>you</i>. Topics range from frontend development to travel or whatever I feel like
            sharing.
          </Text>
          <PostList posts={posts} />
          <TagList />
        </Container>
      </Section>
    </>
  );
};

// GraphQL Queries
export const pageQuery = graphql`
  {
    backgroundImage: file(relativePath: { eq: "tags.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          frontmatter {
            status
            path
            heroImage {
              childImageSharp {
                fluid(quality: 90, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            description
            date(formatString: "MMMM DD, YYYY")
            tags
          }
        }
      }
    }
    allTags: allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
  }
`;
export default PostsPage;
